import React from "react";
import { Link } from "gatsby";
import { Layout, Row, Col, Card } from "antd";
import { Helmet } from "react-helmet";
import Header from "../../components/common/header";
import CommonFooter from "../../components/common/footer";
import TutorialSider from "../../components/guide/tutorial-sider";

import css from "../../styles/guide.css";

class Tutorial extends React.Component {
  render() {
    return (
      <div>
        <Layout>
          <Helmet>
            <meta charSet="utf-8" />
            <meta
              name="description"
              content="DayViewer Tutorials - Read through and follow our tutorials and you will be able to see how DayViewer can help get you more organized!"
            />
            <title>DayViewer Calendar Planner Tutorials</title>
          </Helmet>
          <Header />
          <Layout style={{ minHeight: "100vh" }}>
            <TutorialSider />
            <Layout className="guide-body">
              <h1>DayViewer Tutorial</h1>
              <Row>
                <Col>
                  <p>
                    The best way to learn - is by doing, so we will take you
                    through an example which covers the basic operations. Where
                    features are Pro, this will be indicated. The tutorial is
                    broken down to steps - refer to each step as required
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card
                    style={{ marginBottom: 10 }}
                    title={
                      <Link to={`/tutorial-content/quick-start/`}>
                        Quick Start
                      </Link>
                    }
                  >
                    <p>Here we will learn to set a task up with a checklist.</p>
                  </Card>
                  <Card
                    style={{ marginBottom: 10 }}
                    title={
                      <Link to={`/tutorial-content/tutorial-2/`}>
                        Notes &amp; Labels
                      </Link>
                    }
                  >
                    <p>
                      Notes are a way of keeping information organized, and in
                      DayViewer, can also be used as calendar entry templates.
                      Making it a more efficient way to plan tasks. Labels are
                      very useful to keep information together.
                    </p>
                  </Card>
                  <Card
                    style={{ marginBottom: 10 }}
                    title={
                      <Link to={`/tutorial-content/tutorial-3/`}>
                        Payments Tracking
                      </Link>
                    }
                  >
                    <p>A simple way to do budgeting in DayViewer</p>
                  </Card>
                  <Card
                    style={{ marginBottom: 10 }}
                    title={
                      <Link to={`/tutorial-content/tutorial-4/`}>
                        Recur Entries
                      </Link>
                    }
                  >
                    <p>
                      The visual way to create recurring entries in DayViewer
                    </p>
                  </Card>
                  <Card
                    style={{ marginBottom: 10 }}
                    title={
                      <Link to={`/tutorial-content/team-room/`}>Team Room</Link>
                    }
                  >
                    <p>
                      Setting up a team calendar system is made easier by
                      running through our tutorial here. Get your team created
                      and kick off a task.
                    </p>
                  </Card>
                  <Card
                    style={{ marginBottom: 10 }}
                    title={
                      <Link to={`/tutorial-content/scheduler/`}>
                        Work Scheduler
                      </Link>
                    }
                  >
                    <p>
                      With your team mates and colleagues, our fast work
                      scheduler and planner system makes things unbelievably
                      easy, run through this tutorial to plan your teamwork more
                      efficiently.
                    </p>
                  </Card>
                </Col>
              </Row>
            </Layout>
          </Layout>
          <CommonFooter />
        </Layout>
      </div>
    );
  }
}

export default Tutorial;
